<template>
  <div class="main-content">
    <div class="c-pps container">
      <section class="c-start">
        <div class="c-start__content">
          <h1 class="ttl"><span>物件撮影代行依頼を進める</span></h1>
          <p><span>物件撮影代行は、株式会社RealtyBankの提携企業が行います。</span></p>
          <p><span>物件撮影代行可能なエリアは<a class="anchor" href="#area">こちら</a>をご確認ください。</span></p>
          <p><span>納品される写真の詳細は<a class="anchor" href="#photo">こちら</a>をご確認ください。</span></p>
          <p><span>よくあるご質問は<a class="anchor" href="#faq">こちら</a>をご確認ください。</span></p>
        </div>
        <figure class="c-start__pic"><img src="/img/shooting-request/start_pic01_pc.png" alt=""></figure>
      </section>
      
      <div class="c-step">
        <ol>
          <li>
            <p class="num Oswald">STEP 1</p>
            <div class="box">
              <figure class="pic"><img src="/img/shooting-request/step_icon01.png" alt=""></figure>
              <p class="txt01">申込シートの<br>記入</p>
            </div>
          </li>
          <li>
            <p class="num Oswald">STEP 2</p>
            <div class="box">
              <figure class="pic"><img src="/img/shooting-request/step_icon02.png" alt=""></figure>
              <p class="txt01">撮影日時&見積書を<br>
                メールでご案内</p>
            </div>
          </li>
          <li>
            <p class="num Oswald">STEP 3</p>
            <div class="box">
              <figure class="pic"><img src="/img/shooting-request/step_icon03.png" alt=""></figure>
              <p class="txt01">撮影を<br>
                実施</p>
            </div>
          </li>
          <li>
            <p class="num Oswald">STEP 4</p>
            <div class="box">
              <figure class="pic"><img src="/img/shooting-request/step_icon04.png" alt=""></figure>
              <p class="txt01">撮影写真をメールで<br>
                ユーザーに納品</p>
            </div>
          </li>
          <li>
            <p class="num Oswald">STEP 5</p>
            <div class="box">
              <figure class="pic"><img src="/img/shooting-request/step_icon05.png" alt=""></figure>
              <p class="txt01">請求書をメールで<br>
                ユーザーに送付</p>
            </div>
          </li>
        </ol>
      </div>
      
      <section class="c-application">
        <h2 class="c-title01">物件撮影代行依頼の申込シート</h2>
        <div class="c-application__content">
          <form action="#" method="post" @submit.prevent="sendMail">
            <div class="c-form">
              <div class="c-form__content clearfix">
                <dl>
                  <dt>
                    <div class="inner">
                      <p class="name">物件名</p>
                      <p class="require">必須</p>
                    </div>
                  </dt>
                  <dd>
                    <input type="text" name="property_name" placeholder="省略せずに正確に記載してください。" class="wfull" required v-model="name">
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <div class="inner">
                      <p class="name">号室</p>
                      <p class="any">任意</p>
                    </div>
                  </dt>
                  <dd>
                    <input type="text" name="room_number" placeholder="号室がない場合は「なし」と記載ください。" class="wfull" v-model="room_number">
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <div class="inner">
                      <p class="name">住所</p>
                      <p class="require">必須</p>
                    </div>
                  </dt>
                  <dd>
                    <input type="text" name="address" placeholder="枝番まで正確に記載ください。" class="wfull" required  v-model="address">
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <div class="inner">
                      <p class="name">鍵の受け取り方法</p>
                      <p class="require">必須</p>
                    </div>
                  </dt>
                  <dd>
                    <div class="checklist">
                      <ul>
                        <li class="wfull">
                          <div class="key-box">
                            <div class="check">
                              <label><input type="radio" name="key" value=0 required v-model.number="receipt_method"> 現地キーボックス</label>
                            </div>
                            <div class="number">
                              <input type="text" name="key_number" placeholder="番号を記入してください。" class="wfull" v-model="key_number">
                            </div>
                          </div>
                        </li>
                        <li class="column"><label><input type="radio" name="key" value=1 v-model.number="receipt_method"> 電話してヒアリング</label></li>
                        <li><label><input type="radio" name="key" value=2 v-model.number="receipt_method"> 支店、または店舗で受け取り</label></li>
                        <li class="wfull"><label><input type="radio" name="key" value=3 v-model.number="receipt_method"> 現地で立ち合い</label></li>
                      </ul>
                    </div>
                    <ul class="note01">
                      <li>※現地キーボックス以外の鍵の受け取り手配は別途費用がかかります。</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <div class="inner">
                      <p class="name">種類</p>
                      <p class="require">必須</p>
                    </div>
                  </dt>
                  <dd>
                    <div class="checklist">
                      <ul>
                        <li class="wfull"><label><input type="radio" name="type" value=0 required v-model.number="type"> 室内&emsp;空室物件（立ち合い・日時指定なし） 15カット&emsp;6,600円</label></li>
                        <li class="wfull"><label><input type="radio" name="type" value=1 v-model.number="type"> 室内&emsp;空室物件（立ち合い・日時指定あり） 15カット&emsp;8,800円</label></li>
                        <li class="wfull"><label><input type="radio" name="type" value=2 v-model.number="type"> 室内&emsp;居住中（立ち合い・日時指定あり） 15カット&emsp;11,000円</label></li>
                      </ul>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <div class="inner">
                      <p class="name">オプション</p>
                      <p class="any">任意</p>
                    </div>
                  </dt>
                  <dd>
                    <div class="checklist">
                      <ul>
                        <li class="wfull"><label><input type="checkbox" name="option[]" value=0 v-model.number="option"> 室内&emsp;360°写真撮影&emsp;<br class="sp-only">3カット&emsp;2,500円</label></li>
                        <li class="wfull"><label><input type="checkbox" name="option[]" value=1 v-model.number="option"> 戸建住宅&emsp;外観のみ&emsp;<br class="sp-only">3カット&emsp;2,500円</label></li>
                        <li class="wfull"><label><input type="checkbox" name="option[]" value=2 v-model.number="option"> 共同住宅&emsp;外観・共用部写真撮影&emsp;<br class="sp-only">15カット&emsp;3000円</label></li>
                        <li class="wfull"><label><input type="checkbox" name="option[]" value=3 v-model.number="option"> 近隣環境・施設撮影&emsp;<br class="sp-only">3箇所&emsp;2,000円</label></li>
                        <li class="wfull"><label><input type="checkbox" name="option[]" value=4 v-model.number="option"> 特急納品&emsp;4,000円</label></li>
                      </ul>
                    </div>
                    <ul class="note01">
                      <li>※交通費は一律2,000円(/1物件)でのご請求になります。</li>
                      <li>※100平米以上の場合等、ご要望に応じて撮影箇所、撮影点数ご対応いたします。</li>
                    </ul>
                    <p class="note01">&lt;別途御見積します&gt;</p>
                    <ul class="note01">
                      <li>※撮影エリア、ご依頼内容により工数が異なる場合、別途お見積りが必要になります。</li>
                      <li>※撮影確定後のキャンセル、現地到着後撮影不可の場合、料金がかかる可能性があります。</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <div class="inner">
                      <p class="name">電話番号</p>
                      <p class="require">必須</p>
                    </div>
                  </dt>
                  <dd>
                    <input type="text" name="tel" placeholder="正確に記載ください。" class="wfull" required  v-model="tel">
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <div class="inner">
                      <p class="name">その他</p>
                      <p class="any">任意</p>
                    </div>
                  </dt>
                  <dd>
                    <textarea name="message" class="wfull" v-model="remarks"></textarea>
                  </dd>
                </dl>
              </div>
              <div class="c-form__privacy">
                <div class="c-form__privacy-content">
                  <div class="c-form__privacy-content-scroll">
                    <section>
                      <h3 class="ttl">個人情報保護方針</h3>
                    </section>
                    <section>
                      <p>株式会社RealtyBank（以下「当社」といいます）は当サイトをご利用のお客様からお預かりするお客様個人に関する情報（以下「個人情報」といいます）の取扱いにつきまして、個人情報保護に関する各法令等を遵守するほか、以下の「個人情報保護方針」に従うものとします。</p>
                    </section>
                    <section>
                      <h3 class="ttl">１，個人情報の保護についての考え方</h3>
                      <p>当社は、当社の業務を円滑に行うため、お客様の氏名、住所、電話番号、e-mailアドレス等の情報を取得・利用させていただいております。当社は、これらのお客様の個人情報（以下「個人情報」といいます）の適正な保護を重大な責務と認識し、この責務を果たすために、次の方針のもとで個人情報を取り扱います。<br>
                        （１）個人情報に適用される個人情報の保護に関する法律その他の関係法令を遵守するとともに、一般に公正妥当と認められる個人情報の取り扱いに関する慣行に準拠し、適切に取り扱います。<br>
                        （２）個人情報の取り扱いに関する規定を明確にし、従業者に周知徹底します。また、取引先の不動産業者等（以下「情報提供会社」といいます）に対しても適切に個人情報を取り扱うように要請します。<br>
                        （３）個人情報の取得に際しては、利用目的を特定して通知または公表し、その利用目的に従って個人情報を取り扱います。<br>
                        （４）個人情報の漏洩、紛失、改ざんなどを防止するため、必要な対策を講じて適切な管理を行います。<br>
                        （５）保有する個人情報については、お客様本人からの開示、訂正、削除、利用停止の依頼を所定の窓口でお受けし、誠意をもって対処します。<br>
                        （６）保有する個人情報については、個人情報を取得した日から６ヶ月以内に当社の廃棄基準に基づいて廃棄します。<br>
                        具体的には、以下の内容に従って個人情報を取り扱います。</p>
                    </section>
                    <section>
                      <h3 class="ttl">２，収集・利用目的等</h3>
                      <p>当社は、インターネット不動産物件検索サービス事業を行うことに伴い、事業遂行上必要となる個人情報をサイト上の「資料請求フォーム」で収集して情報提供会社に転送しますが、これらの個人情報は、お客様の同意に基づいた資料請求サービスを提供する目的に限って利用させていただきます。<br>
                        なお、当社のサービスを利用する情報提供会社とは、当サイトを通じて取得した個人情報の目的外の利用を禁止する契約を締結していますが、情報提供会社のサイトで個人情報収集が行われる場合は、そのサイトの個人情報の取り扱い基準によります。<br>
                        &emsp;また、当社は、業務を円滑に進めるため、業務の一部を委託し、業務委託先に対して、必要な範囲で個人情報を提供することがありますが、この場合、当社は、これらの業務委託先との間で取扱いに関する契約の締結をはじめ、適切な監督を行います。</p>
                    </section>
                    <section>
                      <h3 class="ttl">３，第三者への開示・提供</h3>
                      <p>当社は、上記「利用目的等」に記載した情報提供会社及び業務委託先への提供の場合及び以下の何れかに該当する場合を除き、個人情報を第三者へ開示または提供しません。<br>
                        （１）お客様ご本人の同意がある場合。<br>
                        （２）統計的なデータなど本人を識別することができない状態で開示・提供する場合。<br>
                        （３）法令に基づき開示・提供を求められた場合。<br>
                        （４）人の生命、身体または財産の保護のために必要な場合であって、お客様の同意を得ることが困難である場合。<br>
                        （５）国または地方公共団体等が公的な事務を実施する上で、協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合。</p>
                    </section>
                    <section>
                      <h3 class="ttl">４，開示</h3>
                      <p>当社の保有個人データに関して、お客様ご自身の情報の開示をご希望される場合には、お申し出いただいた方がご本人であることを確認した上で、合理的な期間および範囲で回答します。</p>
                    </section>
                    <section>
                      <h3 class="ttl">５，訂正・削除等</h3>
                      <p>当社の保有個人データに関して、お客様ご自身の情報について訂正、追加または削除をご希望される場合には、お申し出いただいた方がご本人であることを確認した上で、合理的な期間および範囲で情報の訂正、追加または削除をします。</p>
                    </section>
                    <section>
                      <h3 class="ttl">６，利用停止・消去</h3>
                      <p>当社の保有個人データに関して、お客様ご自身の情報の利用停止または消去をご希望される場合には、お申し出いただいた方がご本人であることを確認した上で、合理的な範囲で利用停止または消去します。<br>
                        &emsp;これらの情報の一部または全部を利用停止または消去した場合、不本意ながらご要望に沿ったサービスの提供ができなくなることがありますので、ご理解とご協力を賜りますようお願い申し上げます。（ただし、関係法令に基づき保有しております情報については、利用停止または消去のお申し出に応じられない場合があることをご承知おきください）</p>
                    </section>
                    <section>
                      <h3 class="ttl">７，免責</h3>
                      <p>当社は、資料請求先の不動産会社などが行う個人情報の利用に関し、責任を負わないものとします。<br>
                        また、当社の管理下にない資料請求先のウエブサイト、もしくは当社のサイトにリンクしているウエブサイトは、本ポリシーの適用範囲外ですので、あらかじめご了承下さい。</p>
                    </section>
                    <section>
                      <h3 class="ttl">８，開示等の受付方法・窓口</h3>
                      <p>当社の保有個人データに関する、上記４，５，６のお申し出およびその他の個人情報に関するお問い合せは、以下の方法にて受け付けます。<br>
                        &emsp;なお、この受付方法によらない開示・訂正・削除・利用停止・消去等の求めには応じられない場合がありますので、ご了承下さい。<br>
                        <br>
                        （１）受付手続<br>
                        下記の宛先に電話、ＦＡＸ、郵便または電子メールでお申込み下さい。<br>
                        &emsp;受付手続についての詳細は、お申込みいただいた際にご案内申し上げますが、下記の窓口および方法によりご本人（または代理人）であることの確認をした上で、書面の交付その他の方法により、回答します。<br>
                        《受付の方法・窓口》<br>
                        〒060-0041 札幌市中央区大通東3丁目1－12 クリーンリバー大通702号<br>
                        &emsp;&emsp;&emsp;&emsp;e-mail：<a href="mailto:info@realtybank.org">info@realtybank.org</a><br>
                        なお、受付時間は平日の午前９時から午後５時までとなります。<br>
                        《ご本人または代理人の確認》<br>
                        ご本人からお申込みの場合は、ご本人であることを運転免許証・パスポート・健康保険の被保険者証・印鑑証明書等の証明書類で確認させていただきます。<br>
                        &emsp;代理人からお申込みの場合は、代理人であることを委任状および委任状に押印された印鑑の印鑑証明の確認、ご本人への電話等により確認させていただきます。<br>
                        （２）手数料<br>
                        開示などの求めに対し、書留郵送の方法により回答した場合は、１件につき３００円を手数料として、現金その他の方法でお支払いいただきます。<br>
                        <br>
                        以上<br>
                        <br>
                        〒060-0041 札幌市中央区大通東3丁目1－12<br />クリーンリバー大通702号
                        株式会社RealtyBank<br>
                        代表取締役 川上 将司</p>
                    </section>
                  </div>
                </div>
                <p class="c-form__privacy-txt01"><label><input type="checkbox" name="privacy" value="個人情報保護方針に同意する" required>個人情報保護方針に同意する</label></p>
              </div>
              <div class="c-form__submit">
                <input type="submit" value="入力内容を送信する" class="ov">
              </div>
            </div>
          </form>
        </div>
      </section>
      
      <div class="c-important" id="photo">
        <div class="c-important__content">
          <section class="c-important__content-sec">
            <h2 class="c-important__content-sec-ttl" id="area">撮影項目 <span>【要確認】</span></h2>
            <div class="c-important__content-sec-body">
              <div class="c-important__content-sec-body-acc">
                <div class="c-photography-item">
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">玄関<br><span class="small">（内側からドア、<br class="sp-only">足元・靴置き場全体）</span></h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic01.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">玄関収納、<br class="sp-only">開けた状態</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic02.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">キッチン・全体</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic03.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">キッチン・コンロ</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic04.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">キッチン・流し台</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic05.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">トイレ</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic06.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">浴室全体</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic07.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">居室1</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic08.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">居室2</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic09.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">居室収納</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic10.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">眺望</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic11.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">ベランダ</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic12.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">物件入り口・<br class="sp-only">エントランス</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic13.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">建物外観</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic14.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">入口<br class="sp-only">
                        オートロックアップ</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic15.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">ロビー</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic16.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">エレベーター</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic17.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">宅配ボックス</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic18.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">駐輪場</h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic19.png" alt=""></figure>
                  </section>
                  <section class="c-photography-item__sec">
                    <div class="head">
                      <h3 class="ttl">駐車場<br><span class="small">(物件敷地内にある場合) </span></h3>
                    </div>
                    <figure class="pic"><img src="/img/shooting-request/photograph_pic20.png" alt=""></figure>
                  </section>
                </div>
              </div>
              <div class="c-important__content-sec-body-btn js-acc">
                <a href="#" class="ov">
                  <span class="open">撮影項目を確認する</span>
                  <span class="close01">閉じる</span>
                </a>
              </div>
            </div>
          </section>
          <section class="c-important__content-sec ">
            <h2 class="c-important__content-sec-ttl">撮影可能エリア <span>【要確認】</span></h2>
            <div class="c-important__content-sec-body">
              <div class="c-important__content-sec-body-acc">
                <div class="c-photography-area">
                  <table>
                    <thead>
                      <tr>
                        <th>都道府県名</th>
                        <th>対応可否</th>
                        <th>備考</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="is-part">
                        <th>北海道</th>
                        <td>一部対応可</td>
                        <td>札幌市内対応可</td>
                      </tr>
                      <tr class="is-impossible">
                        <th>青森県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>岩手県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>宮城県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>秋田県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>山形県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>福島県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-part">
                        <th>茨城県</th>
                        <td>一部対応可</td>
                        <td>遠方手数料で対応可地域あり※要相談</td>
                      </tr>
                      <tr class="is-part">
                        <th>栃木県</th>
                        <td>一部対応可</td>
                        <td>遠方手数料で対応可地域あり※要相談</td>
                      </tr>
                      <tr class="is-impossible">
                        <th>群馬県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th>埼玉県</th>
                        <td>対応可</td>
                        <td>地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr>
                        <th>千葉県</th>
                        <td>対応可</td>
                        <td>地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr>
                        <th>東京都</th>
                        <td>対応可</td>
                        <td>地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr>
                        <th>神奈川県</th>
                        <td>対応可</td>
                        <td>地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr class="is-impossible">
                        <th>新潟県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-part">
                        <th>富山県</th>
                        <td>一部対応可</td>
                        <td>市内対応可※地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr class="is-part">
                        <th>石川県</th>
                        <td>一部対応可</td>
                        <td>市内対応可※地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr class="is-impossible">
                        <th>福井県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>山梨県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>長野県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-part">
                        <th>岐阜県</th>
                        <td>一部対応可</td>
                        <td>遠方手数料で対応可地域あり※要相談</td>
                      </tr>
                      <tr class="is-part">
                        <th>静岡県</th>
                        <td>一部対応可</td>
                        <td>静岡市内対応可※地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr class="is-part">
                        <th>愛知県</th>
                        <td>一部対応可</td>
                        <td>名古屋市内対応可※地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr class="is-part">
                        <th>三重県</th>
                        <td>一部対応可</td>
                        <td>遠方手数料で対応可地域あり※要相談</td>
                      </tr>
                      <tr class="is-part">
                        <th>滋賀県</th>
                        <td>一部対応可</td>
                        <td>地域によっては遠方手数料にて対応有</td>
                      </tr>
                      <tr class="is-part">
                        <th>京都府</th>
                        <td>一部対応可</td>
                        <td>地域によっては遠方手数料にて対応有</td>
                      </tr>
                      <tr>
                        <th>大阪府</th>
                        <td>対応可</td>
                        <td>地域によっては遠方手数料にて対応有</td>
                      </tr>
                      <tr class="is-part">
                        <th>兵庫県</th>
                        <td>一部対応可</td>
                        <td>地域によっては遠方手数料にて対応有</td>
                      </tr>
                      <tr class="is-part">
                        <th>奈良県</th>
                        <td>一部対応可</td>
                        <td>地域によっては遠方手数料にて対応有</td>
                      </tr>
                      <tr class="is-impossible">
                        <th>和歌山県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>鳥取県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>島根県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>岡山県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-part">
                        <th>広島県</th>
                        <td>一部対応可</td>
                        <td>市内対応可※地域によっては遠方手数料によって対応可</td>
                      </tr>
                      <tr class="is-impossible">
                        <th>山口県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>徳島県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>香川県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>愛媛県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>高知県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-part">
                        <th>福岡県</th>
                        <td>一部対応可</td>
                        <td>市内対応可※地域によっては遠方手数料にて対応可</td>
                      </tr>
                      <tr class="is-impossible">
                        <th>佐賀県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>長崎県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-part">
                        <th>熊本県</th>
                        <td>一部対応可</td>
                        <td>市内対応可※地域によっては遠方手数料によって対応可</td>
                      </tr>
                      <tr class="is-impossible">
                        <th>大分県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>宮崎県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>鹿児島県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                      <tr class="is-impossible">
                        <th>沖縄県</th>
                        <td>対応不可</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="c-important__content-sec-body-btn js-acc" id="faq">
                <a href="#" class="ov">
                  <span class="open">撮影可能エリアを確認する</span>
                  <span class="close01">閉じる</span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
      
      <section class="c-faq">
        <h2 class="c-title01">よくあるご質問</h2>
        
        <div class="c-faq-list">
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">料金は時間制でしょうか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>時間制ではなく、住戸数単位で請求致します。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">交通費は別途請求でしょうか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>一律2,000円～ご請求させていたただいております。<br>
                  エリアにより異なりますのでご依頼の際にお見積りいたします。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">依頼した住戸の工事が延長するなどで当日撮影ができなくなった場合の料金はどうなりますか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>お依頼後、撮影が実施できないことがわかった場合は中断時のご料金を申し受ける場合がございます。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">撮影時間や雨天時の撮影NGなどの指定は可能ですか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>事前の契約取り決めの際にご指定いただくことが可能です。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">依頼から納品までの期間はどのくらいですか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>基本的には1週間以内にご対応可能です。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">撮影後翌日にお写真を納品いただくことは可能ですか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>オプションという形で料金がかかりますが、ご対応可能でございます。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">室内写真は何カット納品されますか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>15カットです。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">外観共有部は何カット納品されますか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>15カットです。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">360°シータ撮影は何カット納品されますか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>3カットです。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">納品方法は何ですか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>ギガファイル便をメールにてお送りいたします。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">支払いはどのタイミングで行いますか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>お写真納品ごご請求書を発行させていただきます。当月締めの翌月末払いになります。</p>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <p class="icon Oswald">Q</p>
              <h2 class="ttl">支払い方法は何ですか？</h2>
            </dt>
            <dd>
              <p class="icon Oswald">A</p>
              <div class="cont">
                <p>銀行振り込みになります。請求書送付の際にご案内させていただきます。</p>
              </div>
            </dd>
          </dl>
        </div>
      </section>
    </div>

    <b-modal
      id="shooting-request-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="closeSuccessModal()"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          物件撮影代行依頼を受け付けました。
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="closeSuccessModal()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { cus_stagingService_SendShootingRequest } from '../../services/customer/staging';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | Shooting Request',
    };
  },

  data() {
    return {
      name: null,
      room_number: null,
      address: null,
      receipt_method: null,
      key_number: null,
      type: null,
      option: [],
      tel: null,
      remarks: null,
    };
  },

  mounted() {
    //PCスマホで画像切り替え
    // 置換の対象とするclass属性。
    var $elem = $('img');
    // 置換の対象とするsrc属性の末尾の文字列。
    var sp = '_sp.';
    var pc = '_pc.';
    // 画像を切り替えるウィンドウサイズ。
    var replaceWidth = 768;

    function imageSwitch() {
      // ウィンドウサイズを取得する。
      var windowWidth = parseInt($(window).width());

      // ページ内にあるすべての`.js-image-switch`に適応される。
      $elem.each(function() {
        var $this = $(this);
        // ウィンドウサイズが768px以上であれば_spを_pcに置換する。
        if(windowWidth >= replaceWidth) {
          $this.attr('src', $this.attr('src').replace(sp, pc));

          // ウィンドウサイズが768px未満であれば_pcを_spに置換する。
        } else {
          $this.attr('src', $this.attr('src').replace(pc, sp));
        }
      });
    }
    imageSwitch();

    // 動的なリサイズは操作後0.2秒経ってから処理を実行する。
    var resizeTimer;
    $(window).on('resize', function() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        imageSwitch();
      }, 200);
    });

    // アンカーリンクのURL変更を無効
    $('.anchor').click(function() {
      var href= $(this).attr("href");
      var hash = href == "#" || href == "" ? 'html' : href;
      var target = $(hash);
      var position = target.offset().top;
      $('body,html').stop().animate({scrollTop:position}, 500);
      return false;
    });

    // アコーディオン
    $(document).on("click", ".js-acc", function(){
      $(this).prev().stop(false,true).slideToggle();
      $(this).toggleClass("is-active");
      return false;
    });
  },

  methods: {
    sendMail() {
      let _this = this;

      _this.$store.state.modalLoaderMessage = '送信中';
      _this.$bvModal.show('modal-loader');

      const accessToken = localStorage.getItem('_accessToken');
      const formData = {
        name: _this.name,
        room_number: _this.room_number,
        address: _this.address,
        receipt_method: _this.receipt_method,
        key_number: _this.key_number,
        type: _this.type,
        option: _this.option,
        tel: _this.tel,
        remarks: _this.remarks,
      };

      cus_stagingService_SendShootingRequest(
        {
          token: accessToken
        },
        formData
      )
        .then((response) => {
          _this.$bvModal.show('shooting-request-success-modal');

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            '送信中にエラー起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },
    closeSuccessModal() {
      this.$bvModal.hide('shooting-request-success-modal');
      this.$router.push({ name: 'userRoute-dashboard' });
    }
  }
};
</script>

<style scoped>
</style>
